<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">members management</div>
      </v-row>
      <v-row class="">
        <div class="">
          View registered members, create, edit and remove members from the
          system. You can also change roles of different members.
        </div>
      </v-row>
    </v-col>

    <div class="mt-3 pa-2">
      <div class="d-flex">
        <v-dialog width="auto" :persistent="addingLoader" v-model="addDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-on="on" v-bind="attrs"
              ><v-icon>mdi-plus</v-icon>new member</v-btn
            >
          </template>

          <v-card width="500">
            <v-card-title>
              <p class="text-uppercase mx-auto">new member</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="ma-0 pa-1">
              <v-stepper v-model="addStepper">
                <v-stepper-header>
                  <v-stepper-step :complete="addForm" step="1">
                    Member Details
                  </v-stepper-step>
                  <v-stepper-step :complete="addingLoader" step="2">
                    Additional Details
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form v-model="addForm" ref="addForm">
                      <v-text-field
                        label="Name"
                        outlined
                        dense
                        :rules="[rules.required]"
                        v-model="addName"
                      ></v-text-field>
                      <v-text-field
                        label="Email"
                        :rules="[rules.required, rules.email]"
                        outlined
                        dense
                        v-model="addEmail"
                      ></v-text-field>
                      <v-text-field
                        label="Username"
                        :rules="[rules.required]"
                        outlined
                        dense
                        v-model="addUserName"
                      ></v-text-field>
                      <v-autocomplete
                        label="Role"
                        :rules="[rules.required]"
                        :items="[
                          'Member',
                          'Clerk',
                          'Clerk Assistant',
                          'Speaker',
                          'Admin',
                        ]"
                        outlined
                        dense
                        v-model="addRole"
                      ></v-autocomplete>
                      <v-autocomplete
                        label="Commitee"
                        :rules="[rules.required]"
                        :items="commiteeNames"
                        outlined
                        dense
                        v-model="addCommitee"
                      ></v-autocomplete>
                      <v-text-field
                        label="Password"
                        :rules="[rules.required]"
                        outlined
                        dense
                        v-model="addPassword"
                      ></v-text-field>
                      <v-btn block color="primary" @click="addStepper = 2"
                        >Next <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-form>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <!-- additional info -->
                    <v-form ref="addForm">
                      <v-text-field
                        label="Title"
                        outlined
                        dense
                        v-model="addTitle"
                      ></v-text-field>
                      <v-text-field
                        label="Party"
                        outlined
                        dense
                        v-model="addParty"
                      ></v-text-field>
                      <v-text-field
                        label="Constituency"
                        outlined
                        dense
                        v-model="addConstituency"
                      ></v-text-field>
                      <v-file-input
                        outlined
                        dense
                        label="Profile Picture"
                        v-model="addPic"
                      ></v-file-input>
                      <v-card-actions>
                        <v-btn @click="addStepper = 1" color="primary">
                          <v-icon>mdi-chevron-left</v-icon> previous</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          :loading="addingLoader"
                          :disabled="!addForm"
                          @click="addMember"
                        >
                          create member
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          outlined
          dense
          label="Search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="members"
        class="text-capitalize elevation-10 mt-2"
      >
        <template v-slot:[`item.committee_type_id`]="{ item }">
          {{ item.committee_type_id == null ? "None" : item.committees.name }}
        </template>
        <template v-slot:[`item.role`]="{ item }">
          {{ item.role.name }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn color="primary" icon @click="editItem(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              icon
              @click="(deleteDialog = true), (deleteItemId = item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <v-dialog width="auto" v-model="editDialog" :persistent="editLoader">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">edit member</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-1">
            <v-stepper v-model="editStepper">
              <v-stepper-header>
                <v-stepper-step :complete="editForm" step="1">
                  Member Details
                </v-stepper-step>
                <v-stepper-step :complete="editLoader" step="2">
                  Additional Details
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form v-model="editForm" ref="editForm">
                    <v-text-field
                      label="Name"
                      outlined
                      dense
                      :rules="[rules.required]"
                      v-model="editName"
                    ></v-text-field>
                    <v-text-field
                      label="Email"
                      :rules="[rules.required, rules.email]"
                      outlined
                      dense
                      v-model="editEmail"
                    ></v-text-field>
                    <v-text-field
                      label="Username"
                      :rules="[rules.required]"
                      outlined
                      dense
                      v-model="editUserName"
                    ></v-text-field>
                    <v-autocomplete
                      label="Role"
                      :items="[
                        'Member',
                        'Clerk',
                        'Clerk Assistant',
                        'Speaker',
                        'Admin',
                      ]"
                      :rules="[rules.required]"
                      outlined
                      dense
                      v-model="editRole"
                    ></v-autocomplete>
                    <v-autocomplete
                      label="Commitee"
                      :rules="[rules.required]"
                      outlined
                      :items="commiteeNames"
                      dense
                      v-model="editCommitee"
                    ></v-autocomplete>
                    <v-btn block color="primary" @click="editStepper = 2"
                      >Next <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-form>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <!-- additional info -->
                  <v-form ref="addForm">
                    <v-text-field
                      label="Title"
                      outlined
                      dense
                      v-model="editTitle"
                    ></v-text-field>
                    <v-text-field
                      label="Party"
                      outlined
                      dense
                      v-model="editParty"
                    ></v-text-field>
                    <v-text-field
                      label="Constituency"
                      outlined
                      dense
                      v-model="editConstituency"
                    ></v-text-field>
                    <v-file-input
                      outlined
                      dense
                      label="Profile Picture"
                      v-model="editPic"
                    ></v-file-input>
                    <v-card-actions>
                      <v-btn @click="editStepper = 1" color="primary">
                        <v-icon>mdi-chevron-left</v-icon> previous</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        :disabled="!editForm"
                        :loading="editLoader"
                        @click="saveChanges"
                      >
                        save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- delete dialog -->

      <v-dialog v-model="deleteDialog" width="auto" :persistent="deleteLoader">
        <v-card>
          <v-card-title>
            <p class="mx-auto text-uppercase">delete member</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="text-center text-subtitle-1">
              Are you sure you want to delete this item?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="deleteDialog = false">
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="memberDelete" :loading="deleteLoader">
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-capitalize text-center">{{ snackBarData.message }}</div>
    </v-snackbar>
  </div>
</template>

<script>
import { memberStore } from "../stores/members";
import { commiteeStore } from "../stores/commitees";
import { mapActions, mapState } from "pinia";
import Api from "@/services/Api";

export default {
  data() {
    return {
      // add member
      addStepper: "1",
      addName: "",
      addEmail: "",
      addUserName: "",
      addRole: "",
      addPassword: "",
      addCommitee: "",
      addTitle: "",
      addConstituency: "",
      addParty: "",
      addPic: null,
      // edit member
      editStepper: "1",
      editName: "",
      editEmail: "",
      editUserName: "",
      editRole: "",
      editCommitee: "",
      editTitle: "",
      editParty: "",
      editConstituency: "",
      editPic: null,
      // misc
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      search: "",
      editForm: false,
      addForm: false,
      rules: {
        required: (v) => !!v || "Required",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      headers: [
        {
          text: "name",
          value: "name",
          class: "text-uppercase black--text",
        },
        {
          text: "email",
          value: "email",
          class: "text-uppercase black--text",
        },
        {
          text: "username",
          value: "username",
          class: "text-uppercase black--text",
        },
        {
          text: "commitee",
          value: "committee_type_id",
          class: "text-uppercase black--text",
        },
        {
          text: "role",
          value: "role",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      addingLoader: false,
      editLoader: false,
      deleteLoader: false,
      deleteItemId: false,
      editedItem: null,
    };
  },
  computed: {
    ...mapState(memberStore, ["member", "members"]),
    ...mapState(commiteeStore, ["commitees", "commiteeNames"]),
  },
  methods: {
    ...mapActions(memberStore, [
      "getMembers",
      "createMember",
      "updateMember",
      "deleteMember",
    ]),
    ...mapActions(commiteeStore, ["getCommitees"]),
    addMember() {
      this.addingLoader = true;

      // get the commitee id
      let committee_type_id;
      // check if no commitee is selected
      if (this.addCommitee == "None") {
        committee_type_id = null;
      } else {
        for (let com of this.commitees) {
          if (com.name == this.addCommitee) {
            committee_type_id = com.id;
          }
        }
      }
      // get the role id
      let role_id;
      switch (this.addRole) {
        case "Admin":
          role_id = 1;
          break;
        case "Member":
          role_id = 2;
          break;
        case "Clerk":
          role_id = 3;
          break;
        case "Clerk Assistant":
          role_id = 4;
          break;
        default:
          role_id = 5;
      }

      // create data object to upload
      let data = {};

      if (committee_type_id == null)
        data = {
          name: this.addName,
          email: this.addEmail,
          username: this.addUserName,
          role_id: role_id,
          password: this.addPassword,
        };
      else
        data = {
          name: this.addName,
          email: this.addEmail,
          username: this.addUserName,
          role_id: role_id,
          committee_type_id: committee_type_id,
          password: this.addPassword,
        };

      // additional details
      data = {
        ...data,
        title: this.addTitle,
        constituency: this.addConstituency,
        party: this.addParty,
        profile: this.addPic,
      };

      // cast into form data
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      this.createMember(formData)
        .then(() => {
          this.$refs.addForm.reset();
          this.showSnackBar(true, "succesfully added user");
          // create a talk user
          let userEmailArray = [];
          userEmailArray.push(this.member.email);
          Api.createTalkUser(this.member.id, {
            name: this.member.name,
            email: userEmailArray,
            role: this.addRole,
          });
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.addDialog = false;
          this.addingLoader = false;
        });
    },
    editItem(item) {
      this.editedItem = item;
      this.editName = item.name;
      this.editEmail = item.email;
      this.editRole = item.role.name;
      // additional info if exists
      this.editConstituency = item.constituency;
      this.editTitle = item.title;
      this.editParty = item.party;
      this.editPic = item.profile;
      // get the cpmmitee name
      let name;
      if (item.committee_type_id === null) {
        name = "None";
      } else
        for (let com of this.commitees) {
          if (com.id === item.committee_type_id) name = com.name;
        }
      this.editCommitee = name;
      this.editUserName = item.username;
      this.editDialog = true;
    },
    saveChanges() {
      this.editLoader = true;

      // get the commitee id
      let committee_type_id;
      // check if no commitee is selected
      if (this.editCommitee === "None") {
        committee_type_id = null;
      } else {
        for (let com of this.commitees) {
          if (com.name == this.editCommitee) {
            committee_type_id = com.id;
          }
        }
      }
      // get the role id
      let role_id;
      switch (this.addRole) {
        case "Admin":
          role_id = 1;
          break;
        case "Member":
          role_id = 2;
          break;
        case "Clerk":
          role_id = 3;
          break;
        case "Clerk Assistant":
          role_id = 4;
          break;
        default:
          role_id = 5;
      }

      // create data object to upload
      let data = {};

      if (committee_type_id == null)
        data = {
          name: this.editName,
          email: this.editEmail,
          role_id: role_id,
          username: this.editUserName,
        };
      else
        data = {
          name: this.editName,
          email: this.editEmail,
          role_id: role_id,
          committee_type_id: committee_type_id,
          username: this.editUserName,
        };

      // append additional info
      data = {
        ...data,
        title: this.addTitle,
        constituency: this.addConstituency,
        party: this.addParty,
        profile: this.addPic,
      };

      // cast into form data
      let formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      this.updateMember(this.editedItem.id, formData)
        .then((res) => {
          this.$refs.editForm.reset();
          this.showSnackBar(true, res.data.messsage);
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.editDialog = false;
          this.editLoader = false;
        });
    },
    memberDelete() {
      this.deleteLoader = true;
      this.deleteMember(this.deleteItemId)
        .then(() => {
          this.showSnackBar(true, "user deleted succesfully");
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.deleteDialog = false;
          this.deleteLoader = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  mounted() {
    this.getMembers();
    this.getCommitees();
  },
};
</script>

<style></style>
